import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GymInfo, GymStatusInfo } from '../../api/main';
import { mainApi, makeAuthorizationHeaderOption } from '../../resources/api';

export interface GymState {
  loading: boolean;
  gymStatuses: GymStatusInfo[];
  gymInfos: GymInfo[];
  error: string;
}

const initialState: GymState = {
  gymInfos: [],
  gymStatuses: [],
  loading: false,
  error: '',
};

export const onGetGymInfos = createAsyncThunk<{ gyms: GymInfo[] }, { token: string }>(
  'gym/getAllGyms',
  async (params) => {
    const res = await mainApi.storeGymStoreGetGymInfos({
      headers: {
        Authorization: 'Bearer ' + params.token,
      },
    });
    console.log("I'm finished ", res.data.gymInfos);
    return { gyms: res.data.gymInfos };
  },
);

export const onGetGymStatuses = createAsyncThunk<GymStatusInfo[], { token: string }>(
  'gym/onGetGyms',
  async (params) => {
    const res = await mainApi.storeGymStoreGetGymStatuses(makeAuthorizationHeaderOption(params.token));
    return res.data.gymStatuses;
  },
);

export const gymSlice = createSlice({
  name: 'gym',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(onGetGymStatuses.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(onGetGymStatuses.fulfilled, (state, action) => {
      state.gymStatuses = action.payload;
      state.loading = false;
    });
    builder.addCase(onGetGymStatuses.rejected, (state) => {
      state.loading = false;
      state.error = 'error';
    });
    builder.addCase(onGetGymInfos.pending, () => {
      console.log('onGetGyms: pending');
    });
    builder.addCase(onGetGymInfos.fulfilled, (state, action) => {
      state.gymInfos = action.payload.gyms;
    });
    builder.addCase(onGetGymInfos.rejected, () => {
      console.log('error');
    });
  },
});

export const gymReducer = gymSlice.reducer;
