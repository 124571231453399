import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { StaffGymListRequestOrderEnum, TemporaryUseGym } from '../../api/main';
import { mainApi, makeAuthorizationHeaderOption } from '../../resources/api';

export type Authorities = {
  userView?: number;
  sale?: number;
  gym?: number;
  log?: number;
  message?: number;
  config?: number;
};

export type AdminState = {
  authorities: Authorities;
  token: string;
  loading: boolean;
  error: boolean;
  staffGymList: TemporaryUseGym[];
};

const initialState: AdminState = {
  authorities: {
    userView: 0,
    sale: 0,
    gym: 0,
    log: 0,
    message: 0,
    config: 0,
  },
  token: '',
  loading: false,
  error: false,
  staffGymList: [],
};

export const onLogin = createAsyncThunk<
  {
    token: string;
    authorities: Authorities;
    staffGymList: TemporaryUseGym[];
  },
  { email: string; password: string }
>('admins/onLogin', async (params) => {
  const res = await mainApi.staffLogin({
    email: params.email,
    password: params.password,
  });
  const encoded = res.data.token.match(/\.(.*)\./gi);
  if (encoded === null) {
    throw 'decode error.';
  }
  const authorities = JSON.parse(atob(encoded[0].slice(1).slice(0, -1))).authorities;
  const newObj: Authorities = {
    userView: authorities.UserView,
    sale: authorities.Sale,
    gym: authorities.Gym,
    log: authorities.Log,
    message: authorities.Message,
    config: authorities.Config,
  };
  const gymListRes = await mainApi.staffGetStaffGymList(
    { order: StaffGymListRequestOrderEnum.Asc, pageNumber: undefined },
    makeAuthorizationHeaderOption(res.data.token),
  );
  const staffGymList = gymListRes.data.gyms;
  return {
    token: res.data.token,
    authorities: newObj,
    staffGymList,
  };
});

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    reset: (state) => {
      //stateのもう一つ深い階層まで指定しないと反映されない
      state.token = initialState.token;
      state.authorities = initialState.authorities;
      state.loading = initialState.loading;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(onLogin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(onLogin.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.authorities = action.payload.authorities;
      state.staffGymList = action.payload.staffGymList;
      state.loading = false;
      state.error = false;
    });
    builder.addCase(onLogin.rejected, (state) => {
      console.log('error');
      state.error = true;
      state.loading = false;
    });
  },
});

export const adminReducer = adminSlice.reducer;
