import '../styles/global.css';

import { datadogRum } from '@datadog/browser-rum';
import type { AppProps } from 'next/app';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { store } from 'src/store/store';
import { ThemeProvider } from 'styled-components';

import { initDataDog } from '@/resources/dataDogHelpers';
import { theme } from '@/styles/theme';

// eslint-disable-next-line @typescript-eslint/naming-convention
const App = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    initDataDog();
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Component {...pageProps} />
      </ThemeProvider>
    </Provider>
  );
};

export default App;
