import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Subscribe, TemporaryUse, UserInfo } from '../../api/main/api';
import { mainApi, makeAuthorizationHeaderOption } from '../../resources/api';
import { genderEnumConverter } from '../../utils/genderEnumConverter';
import { orderEnumConverter } from '../../utils/orderEnumConverter';
import { statusEnumConverter } from '../../utils/statusEnumConverter';

interface UserFilterState {
  pageNumber: number;
  OR: boolean;
  id: string;
  gymId: number;
  firstName: string;
  lastName: string;
  gender: UserGender;
  mainStoreIds: number[];
  statuses: UserStatus;
  order: UserOrder;
}

interface ConfirmModal {
  text: string;
  open: boolean;
}

interface UserState {
  results: UserInfo[];
  filter: UserFilterState;
  numOfUsers: number;
  numOfPages: number;
  temporaryUseList: TemporaryUse[];
  subscribeList: Subscribe[];
  confirmModal: ConfirmModal;
  selected?: UserInfo;
}

export type UserGender = 'notSelected' | 'Man' | 'Woman' | 'No' | 'Other';
export type UserStatus =
  | 'notSelected'
  | 'banned'
  | 'voluntaryDeleted'
  | 'subscribe'
  | 'subValidNullTmpUseThisMonth'
  | 'subValid0TmpUseThisMonth'
  | 'subValidNullTmpUseNotThisMonth'
  | 'subValid0TmpUseNotThisMonth'
  | 'subValid0TmpUseNull'
  | 'subValidNullTmpUseNull';
export type UserOrder = 'asc' | 'desc';

const initialState: UserState = {
  results: [] as UserInfo[],
  filter: {
    pageNumber: 1,
    OR: false,
    id: '',
    gymId: 0,
    firstName: '',
    lastName: '',
    gender: 'notSelected',
    mainStoreIds: [],
    statuses: 'notSelected',
    order: 'desc',
  },
  numOfUsers: 0,
  numOfPages: 0,
  temporaryUseList: [],
  subscribeList: [],
  confirmModal: {
    text: '',
    open: false,
  },
};

export const onGetUser = createAsyncThunk<
  { users: UserInfo[]; numOfUsers: number; numOfPages: number },
  { filter: UserFilterState; token: string }
>('users/onGetUser', async (params) => {
  const gender = params.filter.gender !== 'notSelected' ? genderEnumConverter(params.filter.gender) : undefined;
  const order = orderEnumConverter(params.filter.order);
  const statuses = params.filter.statuses !== 'notSelected' ? [statusEnumConverter(params.filter.statuses)] : [];
  const id = params.filter.id === '' ? undefined : Number(params.filter.id);
  const res = await mainApi.storeUserStoreGetUserByFilter(
    {
      pageNumber: params.filter.pageNumber,
      OR: false,
      id,
      gymId: params.filter.gymId,
      firstName: params.filter.firstName ? params.filter.firstName : undefined,
      lastName: params.filter.lastName ? params.filter.lastName : undefined,
      gender,
      mainStoreIds: params.filter.mainStoreIds,
      statuses,
      order,
    },
    makeAuthorizationHeaderOption(params.token),
  );
  return {
    users: res.data.usersInfo ?? [],
    numOfUsers: res.data.numberOfUsers,
    numOfPages: res.data.numberOfPages,
  };
});

export const userAddTempUseTicket = createAsyncThunk<
  boolean,
  {
    userId: number;
    gymId: number;
    token: string;
  }
>('users/addTempUse', async (params) => {
  await mainApi.storeUserStoreUserAddTempUseTicket(
    {
      userId: params.userId,
      gymId: params.gymId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + params.token,
      },
    },
  );
  return true;
});

export const userGetTempTickets = createAsyncThunk<
  {
    temporaryUseList: TemporaryUse[];
  },
  {
    userId: number;
    token: string;
  }
>('users/getTempUse', async (params) => {
  const res = await mainApi.storeUserStoreGetTempUseTickets(
    {
      userId: params.userId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + params.token,
      },
    },
  );
  console.log('Temp Use Ticket: ', res.data.temporaryUses);
  return { temporaryUseList: res.data.temporaryUses };
});

export const userGetSubscribe = createAsyncThunk<
  {
    subscribeList: Subscribe[];
  },
  {
    userId: number;
    token: string;
  }
>('users/getSubscribe', async (params) => {
  const res = await mainApi.storeUserStoreGetSubscribe(
    {
      userId: params.userId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + params.token,
      },
    },
  );
  console.log('Subscribe: ', res.data.subscribes);
  return { subscribeList: res.data.subscribes };
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset: (state) => {
      //stateのもう一つ深い階層まで指定しないと反映されない
      state.filter = initialState.filter;
      state.numOfPages = initialState.numOfPages;
      state.numOfUsers = initialState.numOfUsers;
      state.results = initialState.results;
      state.temporaryUseList = initialState.temporaryUseList;
      state.subscribeList = initialState.subscribeList;
    },
    setSelectedUser: (state, action: PayloadAction<UserInfo>) => {
      state.selected = action.payload;
    },
    setConfirmModal: (state, action: PayloadAction<ConfirmModal>) => {
      state.confirmModal = action.payload;
    },
    resetConfirmModal: (state) => {
      state.confirmModal.text = '';
      state.confirmModal.open = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(onGetUser.pending, (state, action) => {
      state.filter = action.meta.arg.filter;
    });
    builder.addCase(onGetUser.fulfilled, (state, action) => {
      state.results = action.payload.users;
      state.numOfUsers = action.payload.numOfUsers;
      state.numOfPages = action.payload.numOfPages;
    });
    builder.addCase(onGetUser.rejected, () => {
      console.log('error');
    });
    builder.addCase(userAddTempUseTicket.pending, () => {
      console.log('Add Temp Ticket: Pending.');
    });
    builder.addCase(userAddTempUseTicket.fulfilled, (state) => {
      console.log('Add Temp Ticket: Done');
      state.confirmModal.open = true;
      state.confirmModal.text = '都度利用チケットを追加しました。';
    });
    builder.addCase(userAddTempUseTicket.rejected, (state) => {
      console.log('Add Temp Ticket: Error');
      state.confirmModal.open = true;
      state.confirmModal.text = 'エラーがあります。';
    });
    builder.addCase(userGetTempTickets.pending, () => {
      console.log('Get Temp Ticket: Pending');
    });
    builder.addCase(userGetTempTickets.fulfilled, (state, action) => {
      console.log('Get Temp Ticket: Done');
      state.temporaryUseList = action.payload.temporaryUseList;
    });
    builder.addCase(userGetTempTickets.rejected, () => {
      console.log('Get Temp Ticket: Error');
    });
    builder.addCase(userGetSubscribe.pending, () => {
      console.log('Get Subscribe: Pending');
    });
    builder.addCase(userGetSubscribe.fulfilled, (state, action) => {
      console.log('Get Subscribe: Done');
      state.subscribeList = action.payload.subscribeList;
    });
    builder.addCase(userGetSubscribe.rejected, () => {
      console.log('Get Subscribe: Rejected');
    });
  },
});

export const userReducer = userSlice.reducer;
