import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StoreGetRolesResponseRoles, StoreGetStaffsResponseStaffs } from '../../api/main/api';
import { mainApi } from '../../resources/api';

interface ConfigState {
  roles: StoreGetRolesResponseRoles[];
  staffs: StoreGetStaffsResponseStaffs[];
  selected?: StoreGetStaffsResponseStaffs;
}

const initialState: ConfigState = {
  roles: [] as StoreGetRolesResponseRoles[],
  staffs: [] as StoreGetStaffsResponseStaffs[],
};

interface StaffEditArgs {
  staffId: number;
  gymIds: number[];
  name: string;
  roleId: number;
}

interface StaffCreateArgs {
  email: string;
  password: string;
  name: string;
  roleId: number;
  gymIds: number[];
}

export const onGetRole = createAsyncThunk<{ roles: StoreGetRolesResponseRoles[] }, { token: string }>(
  'configs/onGetRole',
  async (params) => {
    const res = await mainApi.configStoreGetRoles({
      headers: {
        Authorization: 'Bearer ' + params.token,
      },
    });
    return { roles: res.data.roles };
  },
);

export const onGetStaff = createAsyncThunk<{ staffs: StoreGetStaffsResponseStaffs[] }, { token: string }>(
  'configs/onGetStaff',
  async (params) => {
    const res = await mainApi.configStoreGetStaffs({
      headers: {
        Authorization: 'Bearer ' + params.token,
      },
    });
    return { staffs: res.data.staffs };
  },
);

export const onEditStaff = createAsyncThunk<void, { args: StaffEditArgs; token: string }>(
  'configs/onEditStaff',
  async (params) => {
    const res = await mainApi.configStoreEditStaff(
      {
        staffId: params.args.staffId,
        gymIds: params.args.gymIds,
        name: params.args.name,
        roleId: params.args.roleId,
      },
      {
        headers: {
          Authorization: 'Bearer ' + params.token,
        },
      },
    );
  },
);

export const onDeleteStaff = createAsyncThunk<void, { args: number; token: string }>(
  'configs/onDeleteStaff',
  async (params) => {
    await mainApi.configStoreDeleteStaff(
      {
        staffId: params.args,
      },
      {
        headers: {
          Authorization: 'Bearer ' + params.token,
        },
      },
    );
  },
);

export const onCreateStaff = createAsyncThunk<void, { args: StaffCreateArgs; token: string }>(
  'configs/onCreateStaff',
  async (params) => {
    const res = await mainApi.configStoreCreateStaffs(
      {
        email: params.args.email,
        password: params.args.password,
        name: params.args.name,
        roleId: params.args.roleId,
        gymIds: params.args.gymIds,
      },
      {
        headers: {
          Authorization: 'Bearer ' + params.token,
        },
      },
    );
  },
);

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setSelectedStaff: (state, action: PayloadAction<StoreGetStaffsResponseStaffs>) => {
      state.selected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(onGetRole.pending, () => {});
    builder.addCase(onGetRole.fulfilled, (state, action) => {
      state.roles = action.payload.roles;
    });
    builder.addCase(onGetRole.rejected, () => {
      console.log('error');
    });
    builder.addCase(onGetStaff.pending, () => {});
    builder.addCase(onGetStaff.fulfilled, (state, action) => {
      state.staffs = action.payload.staffs;
    });
    builder.addCase(onGetStaff.rejected, () => {
      console.log('error');
    });
    builder.addCase(onEditStaff.pending, () => {});
    builder.addCase(onEditStaff.fulfilled, () => {});
    builder.addCase(onEditStaff.rejected, () => {
      console.log('error');
    });
    builder.addCase(onDeleteStaff.pending, () => {});
    builder.addCase(onDeleteStaff.fulfilled, () => {});
    builder.addCase(onDeleteStaff.rejected, () => {
      console.log('error');
    });
    builder.addCase(onCreateStaff.pending, () => {});
    builder.addCase(onCreateStaff.fulfilled, () => {});
    builder.addCase(onCreateStaff.rejected, () => {
      console.log('error');
    });
  },
});

export const configReducer = configSlice.reducer;
