import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { GetSalesSummariesResponse, SalesSummary } from '../../api/main';
import { mainApi, makeAuthorizationHeaderOption } from '../../resources/api';

export interface SalesSummariesState {
  loading: boolean;
  salesSummaries: SalesSummary[];
  totalCount: number;
  updateDatetime: string;
  error: string;
}

const initialState: SalesSummariesState = {
  loading: false,
  salesSummaries: [],
  totalCount: 0,
  updateDatetime: '',
  error: '',
};

export const onGetSalesSummaries = createAsyncThunk<
  GetSalesSummariesResponse,
  {
    gymId: number;
    limit: number;
    offset: number;
    token: string;
  }
>('sales/onGetSalesSummaries', async (params) => {
  const res = await mainApi.salesSummaryGetSalesSummaries(
    params.gymId,
    params.limit,
    params.offset,
    makeAuthorizationHeaderOption(params.token),
  );
  return res.data;
});

export const saleSummariesSlice = createSlice({
  name: 'saleSummaries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(onGetSalesSummaries.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(onGetSalesSummaries.fulfilled, (state, action) => {
      state.salesSummaries = action.payload.salesSummaries;
      state.totalCount = action.payload.totalCount;
      const date = new Date();
      if (date.getHours() < 1) {
        date.setDate(date.getDate() - 1);
        date.setHours(1);
        date.setMinutes(0);
        state.updateDatetime = date.toString();
      } else {
        date.setHours(1);
        date.setMinutes(0);
        state.updateDatetime = date.toString();
      }
      state.loading = false;
    });
    builder.addCase(onGetSalesSummaries.rejected, (state) => {
      state.loading = false;
      state.error = 'error';
    });
  },
});

export const salesSummariesReducer = saleSummariesSlice.reducer;
