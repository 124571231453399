export const BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL;
export const SOCKET_URL = process.env.NEXT_PUBLIC_SOCKET_BASE_URL || '';

export const ENV_STATE = {
  dev: 'dev',
  staging: 'staging',
  production: 'production',
} as const;

export const DATADOG_ENV = process.env.NEXT_PUBLIC_DATADOG_ENV ?? ENV_STATE.dev;

export const SOCKET_MESSAGE_CHANNEL = 'message';
export const SOCKET_MESSAGE_USER_CHANNEL = 'message/change';
export const SOCKET_MESSAGE_USER_LIST_CHANNEL = 'message/userList';
export const SOCKET_MESSAGE_MODE_COUNT_CHANNEL = 'message/count';
export const SOCKET_MESSAGE_MODE_SINGLE_CHANNEL = 'message/single';

export const SP_VIEW_MAX_WIDTH = 1023;
