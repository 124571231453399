import { datadogRum } from '@datadog/browser-rum';
import { DATADOG_ENV, ENV_STATE } from 'src/constant';

const isDataDogAvailableEnv = DATADOG_ENV === ENV_STATE.production || DATADOG_ENV === ENV_STATE.staging;

export const initDataDog = () => {
  if (isDataDogAvailableEnv) {
    datadogRum.init({
      applicationId: '73e8487e-808d-4763-97dd-a76efc48379a',
      clientToken: 'pubdbcf1153a5687df98ea502e2fb20345d',
      site: 'ap1.datadoghq.com',
      service: 'dashboad',
      env: DATADOG_ENV,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 80,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.startSessionReplayRecording();
  }
};

export const dataDogSetUser = (email: string) => {
  if (isDataDogAvailableEnv) {
    datadogRum.setUser({ email });
  }
};
