import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMessage as GCMessage } from 'react-native-gifted-chat';

import { StaffUserMessagesByGymResponseUsers } from '../../api/main';
import { makeAuthorizationHeaderOption } from '../../resources/api';
import { mainApi } from '../../resources/api';
import { messageUserGymMessageEnumConverter } from '../../utils/messageGymUserMessageEnumConverter';

export interface IEvent<Content, EventName> {
  type: EventName;
  content: Content;
  meta: {
    createDatetime: string;
  };
}

export type IReceivedMessage = {
  message: IMessage<any, any>;
};

type IRead = {
  messageId: number;
  from: MessageAddress;
};

type AddressType = 'gym' | 'user' | 'admin';
export type OfficerAddressType = Exclude<AddressType, 'user'>;

export type ReceivedMessageEvent = IEvent<IReceivedMessage, 'received_message'>;
export type ReadEvent = IEvent<IRead, 'read'>;

export interface IMessage<Content, TypeName> {
  type: TypeName;
  content: Content;
  meta: {
    // message id
    id: number;
    // YYYY/MM/dd hh:mm:ss
    createDatetime: string;
    updateDatetime: string | null;
    from: MessageAddress;
    to: MessageAddress;
    read: boolean;
  };
}

export interface MessageAddress {
  id: number;
  type: AddressType;
  name: string;
  imageUrl: string | null;
}

type IText = { text: string };

export type MessageText = IMessage<IText, 'text'>;

export type MessageUserOrder = 'asc' | 'desc';

interface MessageUserFilterState {
  page: number;
  order: MessageUserOrder;
}

interface MessageUserState {
  filters: MessageUserFilterState;
  results: StaffUserMessagesByGymResponseUsers[];
  selecting?: StaffUserMessagesByGymResponseUsers;
  numOfUsers: number;
  numOfPages: number;
}

export interface Officer {
  id: number;
  type: OfficerAddressType;
  name: string;
}

export interface MessageState {
  content: GCMessage[];
  officer: Officer;
  postContent: {
    messageText: string;
    id: number;
    type: AddressType;
    token: string;
  };
  messageUserState: MessageUserState;
}

const initialState: MessageState = {
  content: [
    {
      _id: 0,
      text: '',
      createdAt: 0,
      user: {
        _id: '',
        name: '',
        avatar: '',
      },
    },
  ],
  officer: {
    id: 0,
    type: 'gym',
    name: '',
  },
  postContent: {
    messageText: '',
    id: 0,
    type: 'user',
    token: '',
  },
  messageUserState: {
    filters: {
      page: 1,
      order: 'desc',
    },
    results: [],
    numOfUsers: 0,
    numOfPages: 0,
  },
};

const findUserIndexInResults = (userId: number, results: StaffUserMessagesByGymResponseUsers[]) => {
  for (let i = 0; i < results.length; ++i) {
    console.log(JSON.stringify(results[i]));
    if (userId === results[i].id) {
      return i;
    }
  }
  return -1;
};

const convertMessageObj = (message: IMessage<any, any>) => {
  return {
    _id: message.meta.id,
    text: message.content.text,
    createdAt: Date.parse(message.meta.createDatetime),
    user: {
      _id: message.meta.from.id,
      name: message.meta.from.name,
      //avatar: require('../../../public/lifefit_team.png') //message.meta.from.imageUrl
      avatar: 'https://gravatar.com/avatar/94d45dbdba988afacf30d916e7aaad69?s=200&d=mp&r=x',
    },
  };
};

const createStaffMessageRequest = (userId: number, gymId: number, checkRead: boolean) => {
  return {
    userId,
    gymId,
    checkRead,
  };
};

export const getAllUserMessagesByGym = createAsyncThunk<
  {
    users: StaffUserMessagesByGymResponseUsers[];
    numberOfPages: number;
    numberOfUsers: number;
  },
  {
    gymId: string;
    order: MessageUserOrder;
    pageNumber: number;
    token: string;
  }
>('user/getAllUserMessagesByGym', async (params) => {
  const order = messageUserGymMessageEnumConverter(params.order);
  const res = await mainApi.storeMessageGetStaffMessageByGym(
    params.gymId,
    {
      order,
      pageNumber: params.pageNumber === undefined || params.pageNumber === 0 ? 1 : params.pageNumber,
    },
    makeAuthorizationHeaderOption(params.token),
  );
  return { users: res.data.users, numberOfUsers: res.data.numberOfUsers, numberOfPages: res.data.numberOfPages };
});

export const getStaffMessagesByUser = createAsyncThunk<
  GCMessage[],
  {
    userId: number;
    gymId: number;
    checkRead: boolean;
    token: string;
  }
>('message/getStaffMessageByUser', async (params) => {
  const staffMessageRequest = createStaffMessageRequest(params.userId, params.gymId, params.checkRead);
  const res = await mainApi.storeMessageGetStaffMessages(
    staffMessageRequest,
    makeAuthorizationHeaderOption(params.token),
  );
  const messageContents: GCMessage[] = res.data.messages.map((message: any) => convertMessageObj(message));
  return messageContents;
});

export const getMessagesByUser = createAsyncThunk<
  GCMessage[],
  {
    id: string;
    token: string;
  }
>('message/getMessageByUser', async (params) => {
  const res = await mainApi.messageGetAdminMessages(params.id, makeAuthorizationHeaderOption(params.token));
  const messageContents: GCMessage[] = res.data.messages.map((message: any) => convertMessageObj(message));
  return messageContents;
});

export const onPostMessage = createAsyncThunk<
  boolean,
  { gymId: string; messageText: string; userId: number; type: OfficerAddressType; token: string }
>('message/onPostMessage', async (params) => {
  const res = await mainApi.storeMessageUserSendStaffMessage(
    params.gymId,
    {
      messageText: params.messageText,
      userId: params.userId,
    },
    makeAuthorizationHeaderOption(params.token),
  );
  return res.data.result;
});

export const updateMessageRead = createAsyncThunk<
  void,
  { gymId: number; messageId: number; checkRead: boolean; token: string }
>('message/changeRead', async (params) => {
  await mainApi.storeMessageStaffChangeMessageRead(
    {
      gymId: params.gymId,
      messageId: params.messageId,
      checkRead: params.checkRead,
    },
    makeAuthorizationHeaderOption(params.token),
  );
});

export const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setSelectingMessageUser: (state, action: PayloadAction<StaffUserMessagesByGymResponseUsers>) => {
      state.messageUserState.selecting = action.payload;
    },
    setSelectingGym: (state, action: PayloadAction<Officer>) => {
      state.officer = action.payload;
    },
    addMessageFromWS: (state, action: PayloadAction<ReceivedMessageEvent>) => {
      const gcMessage = convertMessageObj(action.payload.content.message);
      const meta = action.payload.content.message.meta;
      if (
        (meta.from.id == state.officer.id && meta.from.type == 'gym') ||
        (meta.to.id == state.officer.id && meta.to.type == 'gym')
      ) {
        const userId = meta.from.type == 'gym' ? meta.to.id : meta.from.id;
        const resultsIndex = findUserIndexInResults(userId, state.messageUserState.results);

        if (resultsIndex >= 0) {
          if (Object.prototype.hasOwnProperty.call(state.messageUserState.results[resultsIndex], 'unread')) {
            state.messageUserState.results[resultsIndex].unread = 0;
          }
        }
        state.content.unshift(gcMessage);
      }
    },
    incrementUserUnread: (state, action: PayloadAction<ReceivedMessageEvent>) => {
      const meta = action.payload.content.message.meta;
      if (meta.to.id == state.officer.id && meta.to.type == 'gym') {
        const userId = meta.from.id;
        const resultsIndex = findUserIndexInResults(userId, state.messageUserState.results);

        if (resultsIndex >= 0) {
          if (Object.prototype.hasOwnProperty.call(state.messageUserState.results[resultsIndex], 'unread')) {
            state.messageUserState.results[resultsIndex].unread! += 1;
          }
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStaffMessagesByUser.fulfilled, (state, action) => {
      state.content = action.payload;
    });
    builder.addCase(getStaffMessagesByUser.rejected, () => {
      console.log('error');
    });
    builder.addCase(getAllUserMessagesByGym.fulfilled, (state, action) => {
      state.messageUserState.results = action.payload.users;
      state.messageUserState.numOfUsers = action.payload.numberOfUsers;
      state.messageUserState.numOfPages = action.payload.numberOfPages;
    });
    builder.addCase(getAllUserMessagesByGym.rejected, () => {
      console.log('error');
    });
  },
});

export const messageReducer = messageSlice.reducer;
